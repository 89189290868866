import React, { useEffect, useState } from 'react'
import { useDashboard } from '../../Hooks/useDashboard'
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';

export const Dashboard = () => {

    const [counts, setCounts] = useState({});

    const { getCounts } = useDashboard()

    useEffect(() => {
        (async () => {
            const temp = await getCounts()
            setCounts(temp.data.data)
        })()
    }, [])


    return (
        <Container>
            <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box ></Box>
                                <Typography variant="h6">Total User Counts</Typography>
                                <Typography variant="h2" color="textSecondary">{counts.user_counts}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card >
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box ></Box>
                                <Typography variant="h6">Total Product Counts</Typography>
                                <Typography variant="h2" color="textSecondary">{counts.product_counts}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card >
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box ></Box>
                                <Typography variant="h6">Total Events</Typography>
                                <Typography variant="h2" color="textSecondary">{counts.event_count}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card >
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box ></Box>
                                <Typography variant="h6">Total Archived Users</Typography>
                                <Typography variant="h2" color="textSecondary">{counts.archived_users_counts}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}
