import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import Router from './Pages/Index';
import './style/product.css';
import { LoaderProvider } from './Utils/LoaderContext';
import Loader from './Utils/Loader';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#011638',
      },
      secondary: {
        main: '#b9d6f2'
      }
    }
  });

  return (
    <>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <LoaderProvider>
          <Loader />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </LoaderProvider>
      </ThemeProvider>
    </>
  )
}

export default App;
