import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useUsers from '../../Hooks/useUsers';
import { decryptedBody, encryptData } from '../../Utils/constant';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

const AppUsers = () => {

    const [users, setUsers] = useState([]);

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const navigate = useNavigate();

    const { getUsers, deleteUser } = useUsers()

    useEffect(() => {
        fetchUsers()
    }, [])

    const fetchUsers = async () => {
        const temp = await getUsers();
        const res = decryptedBody(temp.data.data)
        setUsers(res)
    }

    const deleteProducts = async (id) => {
        await deleteUser(id)
        fetchUsers();
        setDeleteConfirm(false)
    }

    return (
        <Grid>
            <Grid display="flex" justifyContent="flex-end" marginBottom={2}>
                <Button variant="contained">
                    Add User
                </Button>
            </Grid>

            <Grid className="product__page">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {['Fristname', 'Last name', 'Email', 'Phone', 'Verified', 'Action'].map((header) => (
                                    <TableCell key={header} style={{ fontWeight: 'bold' }} align="right">{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="right">{row.firstname}</TableCell>
                                    <TableCell align="right">{row.lastname}</TableCell>
                                    <TableCell align="right">{row.email}</TableCell>
                                    <TableCell align="right">{row.phone}</TableCell>
                                    <TableCell align="right">{row.isVerify ?
                                        <Tooltip title="Done">
                                            <DoneIcon />
                                        </Tooltip>
                                        :
                                        <Tooltip title="Pending">
                                            <HourglassEmptyIcon />
                                        </Tooltip>
                                    }</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => {
                                            const email = encryptData(row.email);
                                            navigate(`/events`, { state: email });
                                        }}>
                                            <VisibilityIcon />
                                        </IconButton>

                                        {/*<IconButton onClick={() => { setDeleteConfirm(true); setDeleteId(row.id); }}>
                                            <DeleteIcon />
                                        </IconButton>*/}
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>



            {/* DELETE CONFIRM */}
            <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
                <DialogContent>
                    This action can't be reverted.
                    Are you sure you want to delete this user?
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='contained' onClick={() => deleteProducts(deleteId)}>
                        <DeleteIcon style={{ width: '20px', marginRight: '5px' }} />
                        Delete
                    </Button>
                    <Button size='small' variant='outlined' onClick={() => setDeleteConfirm(false)}>Close</Button>
                </DialogActions>
            </Dialog>

        </Grid>
    )
}

export default AppUsers