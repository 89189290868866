let baseURL;
const env = "production"
if (env === 'production') {
    baseURL = 'https://api.salvussequr.com';
} else if (env === 'stage') {
    baseURL = 'https://stage.salvussequr.com';
} else if (env === 'development') {
    baseURL = 'http://localhost:5000';
} else {
    baseURL = 'https://api.salvussequr.com';
}

export { baseURL };

export const PRODUCT_API = {
    GET_LIST: `${baseURL}/api/v1/get-products`,
    ADD_PRODUCT: `${baseURL}/api/v2/addProduct`,
    DELETE_PRODUCT: `${baseURL}/api/v1/delete-product`
}

export const USER_API = {
    GET_LIST: `${baseURL}/api/v1/get-users`,
    DELETE_USER: `${baseURL}/api/v1/remove-user`
}

export const HELP_API = {
    GET_LIST: `${baseURL}/api/v1/get-user-issue`,
    GET_ISSUE_CATEGORY_LIST: `${baseURL}/api/v1/get-help-category`,
    CREATE_ISSUE_CATEGORY: `${baseURL}/api/v1/add-help-category`,
}

export const EVENT_API = {
    GET_LIST: `${baseURL}/api/v1/get-event-records-by-email`
}

export const DASHBOARD_API = {
    GET_COUNTS: `${baseURL}/api/v1/get-dashboard-counts`
}