import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHelp } from '../../Hooks/useHelp'
import { decryptedBody } from '../../Utils/constant';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';

const UserIssues = () => {

    const [issues, setIssues] = useState([]);

    const { getIssues } = useHelp();

    useEffect(() => {
        fetchList();
    }, [])

    const fetchList = async () => {
        const issue = await getIssues()
        if (issue.data.data) {
            const temp = decryptedBody(issue.data.data)
            setIssues(temp);
        }
    }

    return (
        <>
            <Grid className="product__page">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {['Name', 'Category', 'email', 'Number', 'status', 'Details', 'Ticket Number', 'Raised Date/Time', 'Action'].map((header) => (
                                    <TableCell key={header} style={{ fontWeight: 'bold' }} align="right">{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {issues.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="right">{row.name}</TableCell>
                                    <TableCell align="right">{row.category}</TableCell>
                                    <TableCell align="right">{row.email}</TableCell>
                                    <TableCell align="right">{row.number}</TableCell>
                                    <TableCell align="right">{row.status}</TableCell>
                                    <TableCell align="right">{row.details}</TableCell>
                                    <TableCell align="right">{row.ticket_no}</TableCell>
                                    <TableCell align="right">{moment(row.createdAt).format('LLL')}</TableCell>
                                    <TableCell align="right">
                                        <IconButton>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default UserIssues