import React from 'react';
import { AppBar, Toolbar, Typography, Grid, IconButton, Avatar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const navigate = useNavigate()
    const handleLogout = () => {
        localStorage.clear()
        navigate('/')
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="h6" noWrap>
                            Salvus
                        </Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                        <IconButton color="inherit">
                            <Avatar alt="User Avatar" src="/static/images/avatar/1.jpg" />
                        </IconButton>
                        <Button color="inherit" onClick={handleLogout}>
                            Logout
                        </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
