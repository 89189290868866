import axios from 'axios'
import { useLoader } from '../Utils/LoaderContext'
import { DASHBOARD_API } from '../Utils/API'

export const useDashboard = () => {

    const { showLoader, hideLoader } = useLoader()

    const getCounts = async () => {
        showLoader()
        const res = await axios.post(DASHBOARD_API.GET_COUNTS);
        hideLoader();
        return res;
    }

    return { getCounts }
}
