import CryptoJS from 'crypto-js';

const secretKey = "7h#J2$LP@9s&5*F!"

const iv_string = '1M6V!r9b&nB@j4Xc';

export const DESCRYPTION_TYPE = {
    JSON: 'JSON',
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER'
}

export const decryptData = (data, type) => {
    try {
        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const iv = CryptoJS.enc.Utf8.parse(iv_string);
        const decrypted = CryptoJS.AES.decrypt(data, key, { iv: iv });
        const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

        if (type === DESCRYPTION_TYPE.JSON) {
            return JSON.parse(decryptedData);
        }

        if (type === DESCRYPTION_TYPE.STRING) {
            return decryptedData.replaceAll(`"`, "")
        }

        if (type === DESCRYPTION_TYPE.NUMBER) {
            return +decryptedData.replaceAll(`"`, "")
        }

    } catch (error) {
        console.error("Decryption failed:", error.message);
        throw new Error('Decryption failed. Invalid data or incorrect key/iv.');
    }
};


export const encryptData = (data) => {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.enc.Utf8.parse(iv_string);
    return CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv }).toString();
}

export const encryptedBody = (body) => {
    let encryptedData = {};
    const bodyData = decryptData(body, DESCRYPTION_TYPE.JSON)
    for (const key of Object.keys(bodyData)) {
        if (bodyData.hasOwnProperty(key)) {
            encryptedData[key] = encryptData(bodyData[key]);
        }
    }
    return encryptedData;
}
export const decryptedBody = (body) => {
    let data = []
    body.forEach(element => {
        let decryptedData = {};
        for (const key of Object.keys(element)) {
            if (element.hasOwnProperty(key)
                && element[key]
                && key !== 'uniqueId'
                && key !== 'updatedAt'
                && key !== 'qrPath'
                && key !== 'id'
                && key !== 'createdAt'
                && key !== 'isVerify'
                && key !== 'isArchive'
                && key !== 'ticket_no'
                && key !== 'status'
                && key !== 'barcodePath') {
                decryptedData[key] = decryptData(element[key], DESCRYPTION_TYPE.STRING);
            } else {
                decryptedData[key] = element[key]
            }
        }
        data.push(decryptedData)
    });
    return data;
}
