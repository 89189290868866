// Loader.js
import { LinearProgress } from '@mui/material';
import React from 'react';
import { useLoader } from './LoaderContext';


const Loader = () => {
    const { loading } = useLoader();

    if (!loading) return null;

    return (
        <LinearProgress
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 99,
            }}
        />
    );
};

export default Loader;
