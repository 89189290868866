import axios from "axios"
import { useLoader } from "../Utils/LoaderContext"
import { HELP_API } from "../Utils/API"

export const useHelp = () => {

    const { showLoader, hideLoader } = useLoader()
    const getIssues = async () => {
        showLoader()
        const res = await axios.post(HELP_API.GET_LIST);
        hideLoader();
        return res
    }

    return { getIssues }
}
