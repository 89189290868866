export const countryCode = [
    { "country": "United States", "code": "+1", "flag": "🇺🇸" },
    { "country": "Canada", "code": "+1", "flag": "🇨🇦" },
    { "country": "Russia", "code": "+7", "flag": "🇷🇺" },
    { "country": "Egypt", "code": "+20", "flag": "🇪🇬" },
    { "country": "South Africa", "code": "+27", "flag": "🇿🇦" },
    { "country": "Greece", "code": "+30", "flag": "🇬🇷" },
    { "country": "Netherlands", "code": "+31", "flag": "🇳🇱" },
    { "country": "Belgium", "code": "+32", "flag": "🇧🇪" },
    { "country": "France", "code": "+33", "flag": "🇫🇷" },
    { "country": "Spain", "code": "+34", "flag": "🇪🇸" },
    { "country": "Hungary", "code": "+36", "flag": "🇭🇺" },
    { "country": "Italy", "code": "+39", "flag": "🇮🇹" },
    { "country": "Romania", "code": "+40", "flag": "🇷🇴" },
    { "country": "Switzerland", "code": "+41", "flag": "🇨🇭" },
    { "country": "Austria", "code": "+43", "flag": "🇦🇹" },
    { "country": "United Kingdom", "code": "+44", "flag": "🇬🇧" },
    { "country": "Denmark", "code": "+45", "flag": "🇩🇰" },
    { "country": "Sweden", "code": "+46", "flag": "🇸🇪" },
    { "country": "Norway", "code": "+47", "flag": "🇳🇴" },
    { "country": "Poland", "code": "+48", "flag": "🇵🇱" },
    { "country": "Germany", "code": "+49", "flag": "🇩🇪" },
    { "country": "Peru", "code": "+51", "flag": "🇵🇪" },
    { "country": "Mexico", "code": "+52", "flag": "🇲🇽" },
    { "country": "Cuba", "code": "+53", "flag": "🇨🇺" },
    { "country": "Argentina", "code": "+54", "flag": "🇦🇷" },
    { "country": "Brazil", "code": "+55", "flag": "🇧🇷" },
    { "country": "Chile", "code": "+56", "flag": "🇨🇱" },
    { "country": "Colombia", "code": "+57", "flag": "🇨🇴" },
    { "country": "Venezuela", "code": "+58", "flag": "🇻🇪" },
    { "country": "Malaysia", "code": "+60", "flag": "🇲🇾" },
    { "country": "Australia", "code": "+61", "flag": "🇦🇺" },
    { "country": "Indonesia", "code": "+62", "flag": "🇮🇩" },
    { "country": "Philippines", "code": "+63", "flag": "🇵🇭" },
    { "country": "New Zealand", "code": "+64", "flag": "🇳🇿" },
    { "country": "Singapore", "code": "+65", "flag": "🇸🇬" },
    { "country": "Thailand", "code": "+66", "flag": "🇹🇭" },
    { "country": "Japan", "code": "+81", "flag": "🇯🇵" },
    { "country": "South Korea", "code": "+82", "flag": "🇰🇷" },
    { "country": "Vietnam", "code": "+84", "flag": "🇻🇳" },
    { "country": "China", "code": "+86", "flag": "🇨🇳" },
    { "country": "Turkey", "code": "+90", "flag": "🇹🇷" },
    { "country": "India", "code": "+91", "flag": "🇮🇳" },
    { "country": "Pakistan", "code": "+92", "flag": "🇵🇰" },
    { "country": "Afghanistan", "code": "+93", "flag": "🇦🇫" },
    { "country": "Sri Lanka", "code": "+94", "flag": "🇱🇰" },
    { "country": "Myanmar", "code": "+95", "flag": "🇲🇲" },
    { "country": "Iran", "code": "+98", "flag": "🇮🇷" },
    { "country": "South Sudan", "code": "+211", "flag": "🇸🇸" },
    { "country": "Morocco", "code": "+212", "flag": "🇲🇦" },
    { "country": "Algeria", "code": "+213", "flag": "🇩🇿" },
    { "country": "Tunisia", "code": "+216", "flag": "🇹🇳" },
    { "country": "Libya", "code": "+218", "flag": "🇱🇾" },
    { "country": "Gambia", "code": "+220", "flag": "🇬🇲" },
    { "country": "Senegal", "code": "+221", "flag": "🇸🇳" },
    { "country": "Mauritania", "code": "+222", "flag": "🇲🇷" },
    { "country": "Mali", "code": "+223", "flag": "🇲🇱" },
    { "country": "Guinea", "code": "+224", "flag": "🇬🇳" },
    { "country": "Ivory Coast", "code": "+225", "flag": "🇨🇮" },
    { "country": "Burkina Faso", "code": "+226", "flag": "🇧🇫" },
    { "country": "Niger", "code": "+227", "flag": "🇳🇪" },
    { "country": "Togo", "code": "+228", "flag": "🇹🇬" },
    { "country": "Benin", "code": "+229", "flag": "🇧🇯" },
    { "country": "Mauritius", "code": "+230", "flag": "🇲🇺" },
    { "country": "Liberia", "code": "+231", "flag": "🇱🇷" },
    { "country": "Sierra Leone", "code": "+232", "flag": "🇸🇱" },
    { "country": "Ghana", "code": "+233", "flag": "🇬🇭" },
    { "country": "Nigeria", "code": "+234", "flag": "🇳🇬" },
    { "country": "Chad", "code": "+235", "flag": "🇹🇩" },
    { "country": "Central African Republic", "code": "+236", "flag": "🇨🇫" },
    { "country": "Cameroon", "code": "+237", "flag": "🇨🇲" },
    { "country": "Cape Verde", "code": "+238", "flag": "🇨🇻" },
    { "country": "Sao Tome and Principe", "code": "+239", "flag": "🇸🇹" },
    { "country": "Equatorial Guinea", "code": "+240", "flag": "🇬🇶" },
    { "country": "Gabon", "code": "+241", "flag": "🇬🇦" },
    { "country": "Congo", "code": "+242", "flag": "🇨🇬" },
    { "country": "Democratic Republic of the Congo", "code": "+243", "flag": "🇨🇩" },
    { "country": "Angola", "code": "+244", "flag": "🇦🇴" },
    { "country": "Guinea-Bissau", "code": "+245", "flag": "🇬🇼" },
    { "country": "British Indian Ocean Territory", "code": "+246", "flag": "🇮🇴" },
    { "country": "Seychelles", "code": "+248", "flag": "🇸🇨" },
    { "country": "Sudan", "code": "+249", "flag": "🇸🇩" },
    { "country": "Rwanda", "code": "+250", "flag": "🇷🇼" },
    { "country": "Ethiopia", "code": "+251", "flag": "🇪🇹" },
    { "country": "Somalia", "code": "+252", "flag": "🇸🇴" },
    { "country": "Djibouti", "code": "+253", "flag": "🇩🇯" },
    { "country": "Kenya", "code": "+254", "flag": "🇰🇪" },
    { "country": "Tanzania", "code": "+255", "flag": "🇹🇿" },
    { "country": "Uganda", "code": "+256", "flag": "🇺🇬" },
    { "country": "Burundi", "code": "+257", "flag": "🇧🇮" },
    { "country": "Mozambique", "code": "+258", "flag": "🇲🇿" },
    { "country": "Zambia", "code": "+260", "flag": "🇿🇲" },
    { "country": "Madagascar", "code": "+261", "flag": "🇲🇬" },
    { "country": "Reunion", "code": "+262", "flag": "🇷🇪" },
    { "country": "Zimbabwe", "code": "+263", "flag": "🇿🇼" },
    { "country": "Namibia", "code": "+264", "flag": "🇳🇦" },
    { "country": "Malawi", "code": "+265", "flag": "🇲🇼" },
    { "country": "Lesotho", "code": "+266", "flag": "🇱🇸" },
    { "country": "Botswana", "code": "+267", "flag": "🇧🇼" },
    { "country": "Swaziland", "code": "+268", "flag": "🇸🇿" },
    { "country": "Comoros", "code": "+269", "flag": "🇰🇲" },
    { "country": "Saint Helena", "code": "+290", "flag": "🇸🇭" },
    { "country": "Eritrea", "code": "+291", "flag": "🇪🇷" },
    { "country": "Aruba", "code": "+297", "flag": "🇦🇼" },
    { "country": "Faroe Islands", "code": "+298", "flag": "🇫🇴" },
    { "country": "Greenland", "code": "+299", "flag": "🇬🇱" },
    { "country": "Gibraltar", "code": "+350", "flag": "🇬🇮" },
    { "country": "Portugal", "code": "+351", "flag": "🇵🇹" },
    { "country": "Luxembourg", "code": "+352", "flag": "🇱🇺" },
    { "country": "Ireland", "code": "+353", "flag": "🇮🇪" },
    { "country": "Iceland", "code": "+354", "flag": "🇮🇸" },
    { "country": "Albania", "code": "+355", "flag": "🇦🇱" },
    { "country": "Malta", "code": "+356", "flag": "🇲🇹" },
    { "country": "Cyprus", "code": "+357", "flag": "🇨🇾" },
    { "country": "Finland", "code": "+358", "flag": "🇫🇮" },
    { "country": "Bulgaria", "code": "+359", "flag": "🇧🇬" },
    { "country": "Lithuania", "code": "+370", "flag": "🇱🇹" },
    { "country": "Latvia", "code": "+371", "flag": "🇱🇻" },
    { "country": "Estonia", "code": "+372", "flag": "🇪🇪" },
    { "country": "Moldova", "code": "+373", "flag": "🇲🇩" },
    { "country": "Armenia", "code": "+374", "flag": "🇦🇲" },
    { "country": "Belarus", "code": "+375", "flag": "🇧🇾" },
    { "country": "Andorra", "code": "+376", "flag": "🇦🇩" },
    { "country": "Monaco", "code": "+377", "flag": "🇲🇨" },
    { "country": "San Marino", "code": "+378", "flag": "🇸🇲" },
    { "country": "Vatican City", "code": "+379", "flag": "🇻🇦" },
    { "country": "Ukraine", "code": "+380", "flag": "🇺🇦" },
    { "country": "Serbia", "code": "+381", "flag": "🇷🇸" },
    { "country": "Montenegro", "code": "+382", "flag": "🇲🇪" },
    { "country": "Kosovo", "code": "+383", "flag": "🇽🇰" },
    { "country": "Croatia", "code": "+385", "flag": "🇭🇷" },
    { "country": "Slovenia", "code": "+386", "flag": "🇸🇮" },
    { "country": "Bosnia and Herzegovina", "code": "+387", "flag": "🇧🇦" },
    { "country": "North Macedonia", "code": "+389", "flag": "🇲🇰" },
    { "country": "Czech Republic", "code": "+420", "flag": "🇨🇿" },
    { "country": "Slovakia", "code": "+421", "flag": "🇸🇰" },
    { "country": "Liechtenstein", "code": "+423", "flag": "🇱🇮" },
    { "country": "Falkland Islands", "code": "+500", "flag": "🇫🇰" },
    { "country": "Belize", "code": "+501", "flag": "🇧🇿" },
    { "country": "Guatemala", "code": "+502", "flag": "🇬🇹" },
    { "country": "El Salvador", "code": "+503", "flag": "🇸🇻" },
    { "country": "Honduras", "code": "+504", "flag": "🇭🇳" },
    { "country": "Nicaragua", "code": "+505", "flag": "🇳🇮" },
    { "country": "Costa Rica", "code": "+506", "flag": "🇨🇷" }
];  