import axios from 'axios'
import { USER_API } from '../Utils/API'
import { useLoader } from '../Utils/LoaderContext'

const useUsers = () => {

  const { showLoader, hideLoader } = useLoader()

  const getUsers = async () => {
    showLoader()
    const res = await axios.post(USER_API.GET_LIST)
    hideLoader()
    return res;
  }

  const deleteUser = async (id) => {
    showLoader()
    const res = await axios.post(USER_API.DELETE_USER, { id })
    hideLoader()
    return res
  }

  return { getUsers, deleteUser }
}

export default useUsers