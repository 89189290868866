import axios from 'axios'
import { PRODUCT_API } from '../Utils/API'
import { encryptData } from '../Utils/constant'
import { useLoader } from '../Utils/LoaderContext'

const useProduct = () => {

    const { showLoader, hideLoader } = useLoader()

    const getProducts = async () => {
        showLoader();
        const res = await axios.post(PRODUCT_API.GET_LIST);
        hideLoader()
        return res
    }

    const addProduct = async (body) => {
        showLoader();
        const data = encryptData(body)
        const res = await axios.post(PRODUCT_API.ADD_PRODUCT, { data })
        hideLoader();
        return res
    }

    const deleteProduct = async (id) => {
        showLoader();
        const res = await axios.post(PRODUCT_API.DELETE_PRODUCT, { id })
        hideLoader();
        return res;
    }

    return { getProducts, addProduct, deleteProduct }
}

export default useProduct