import { Avatar, Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { toast } from 'react-toastify';

const Login = () => {

  const [user, setUser] = useState({});
  const navigation = useNavigate()

  const username = "hardik"
  const password = "12345"

  return (
    <Grid height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })}

            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {

                if (username === user.username && password === user.password) {
                  localStorage.setItem('user', true)
                  navigation('/home')
                } else {
                  toast.error('Invalid credentials');
                }
              }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </Grid>
  )
}

export default Login