import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Layout from '../Components/Layout/Layout'
import Login from './Login'
import ProductList from './Product/ProductList'
import AppUsers from './Users/AppUsers'
import UserIssues from './Issues/UserIssues'
import List from './Events/List'
import { Dashboard } from './Dashboard/Dashboard'

const Router = () => {

    const user = localStorage.getItem('user')
    const navigate = useNavigate()

    useEffect(() => {
        if (!localStorage.getItem('user')) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <>
            {!user ?
                <Routes>
                    <Route path="/" Component={Login} />
                </Routes>
                :
                <Layout>
                    <Routes>
                        <Route path='/home' Component={Dashboard} />
                        <Route path='/products' Component={ProductList} />
                        <Route path='/app-users' Component={AppUsers} />
                        <Route path='/user-issues' Component={UserIssues} />
                        <Route path='/events' Component={List} />
                    </Routes>
                </Layout>
            }
        </>
    )
}

export default Router