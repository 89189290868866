import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import InboxIcon from '@mui/icons-material/Inbox';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SupportIcon from '@mui/icons-material/Support';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../Header/Header';

const Layout = ({ children }) => {
    const location = useLocation()

    return (
        <>
            <Header />
            <div className='layout'>
                <div className='sidebar'>
                    <Link to="/home" className={`${location.pathname === '/home' ? 'active' : ""}`}>
                        <DashboardIcon />
                        Dashbord
                    </Link>
                    <Link to="/products" className={`${location.pathname === '/products' ? 'active' : ""}`}>
                        <QrCodeScannerIcon />
                        Product
                    </Link>
                    <Link to="/app-users" className={`${location.pathname === '/app-users' ? 'active' : ""}`}>
                        <GroupIcon />
                        Users
                    </Link>
                    <Link to="/events" className={`${location.pathname.includes('events') ? 'active' : ""}`}>
                        <AccountBalanceWalletIcon />
                        Events
                    </Link>
                    <Link to="/qr-requests" className={`${location.pathname === '/qr-requests' ? 'active' : ""}`}>
                        <InboxIcon />
                        QR Request
                    </Link>
                    <Link to="/user-issues" className={`${location.pathname === '/user-issues' ? 'active' : ""}`}>
                        <SupportIcon />
                        User Issue
                    </Link>
                </div>
                <div className='content'>
                    {children}
                </div>
            </div>
        </>
    )
}

export default Layout